<template>
  <v-container id="map-container" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12" md="12">
        <material-card color="primary" full-header class="card-group">
          <template #heading>
            <div class="pa-5 white--text">
              <div class="text-h4 font-weight-bold text-truncate">
                <v-icon large class="mr-3">
                  mdi-map
                </v-icon>
                智慧交通車流即時資訊儀表板
              </div>
            </div>
          </template>
          <v-col cols="12" class="pa-5 pt-0">
            <div id="map" class="map" ref="map" />
            <div ref="chartTL" class="chart-pop-TL" />
            <div ref="chartTR" class="chart-pop-TR" />
            <div ref="chartBL" class="chart-pop-BL" />
            <div ref="chartCL" class="chart-pop-CL">
              <div class="chart-title">
                環湖公路( 向山-水社 )
              </div>
              <div class="chart-subtitle">
                This is subtitle
              </div>
              <div class="chart-key-value-1">
                18
                <span>
                  km/hr
                </span>
              </div>
            </div>
            <div ref="chartCR" class="chart-pop-CR">
              <div class="chart-title">
                台21( 台21甲-線道131 )
              </div>
              <div class="chart-subtitle">
                This is subtitle
              </div>
              <div class="chart-key-value-2">
                34
                <span>
                  km/hr
                </span>
              </div>
            </div>
            <div ref="chartBR" class="chart-pop-BR" />
          </v-col>
        </material-card>
      </v-col>
    </v-row>
    <!-- 由於 Indoor Map 寫在 index.html，每個 Component 都要建一個 id="myMap" 的元素，否則 console 會有錯誤訊息，更嚴謹的處理方案待研究。 -->
    <div id="myMap" />
  </v-container>
</template>

<script>
// import Map, { getLayers } from 'ol/Map.js'
import Map from 'ol/Map.js'
import '../../node_modules/ol/ol.css'
import OSM from 'ol/source/OSM.js'
import TileLayer from 'ol/layer/Tile'

import View from 'ol/View.js'
// import { fromLonLat, toLonLat } from 'ol/proj.js'
import { fromLonLat } from 'ol/proj.js'

export default {
  name: 'MapOpenlayer',
  data () {
    return {
      map: null,
      options: {}
    }
  },
  mounted () {
    setTimeout(() => {
      this.mapint()
      this.chartTlInt()
      this.chartTrInt()
      this.chartBlInt()
      this.chartBrInt()
    }, 100)
  },
  methods: {
    mapint () {
      // var map = this.$refs.map
      var layer = new TileLayer({
        source: new OSM(),
        wrapX: false
      })
      this.$refs.map = new Map({
        target: 'map',
        layers: [layer],
        view: new View({
          center: fromLonLat([121.56994, 25.078341]),
          zoom: 17
        })
      })
    },
    chartTlInt () {
      const labelData = [
        '向山-水社',
        '水社-朝霧碼頭',
        '朝霧碼頭-九龍口',
        '九龍口-文武廟',
        '文武廟-孔雀園',
        '孔雀園-大竹湖',
        '大竹湖-水蛙頭',
        '水蛙頭-纜車站'
      ]
      const valueData = [33, 56, 85, 25, 65, 36, 74, 55]
      const myChartTL = this.$echarts.init(this.$refs.chartTL)
      myChartTL.setOption({
        title: {
          text: '環湖路段績效排名'
        },
        grid: {
          left: '5',
          right: '20',
          top: '40',
          bottom: '30'
        },
        xAxis: {
          show: false
        },
        tooltip: {
          show: true
        },
        yAxis: [
          {
            splitLine: 'none',
            axisLine: 'none',
            axisLabel: {
              verticalAlign: 'bottom',
              align: 'left',
              padding: [0, 0, 10, 10], // 文字与柱状图的距离
              textStyle: {
                color: '#000',
                fontSize: '14'
              }
            },
            data: labelData
          },
          {
            axisLine: 'none',
            data: valueData,
            axisLabel: {
              show: true,
              verticalAlign: 'bottom',
              align: 'right',
              padding: [0, -10, -10, 0], // 数据与图形的距离
              textStyle: {
                color: '#000',
                fontSize: '14'
              }
            }
          }
        ],
        series: [
          {
            type: 'bar',
            showBackground: true,
            data: valueData,
            barWidth: 15,
            itemStyle: {}
          }
        ],
        visualMap: {
          orient: 'horizontal',
          left: 'center',
          min: 10,
          max: 100,
          text: ['High', 'Low'],
          textStyle: {
            color: 'black',
            fontSize: 10
          },
          // Map the score column to color
          dimension: 0,
          inRange: {
            color: ['#ffcf46', '#ff0000']
          }
        }
      })
      window.addEventListener('resize', () => {
        // 自动渲染echarts
        myChartTL.resize()
      })
    },
    chartTrInt () {
      const labelData = [
        '向山-水社',
        '水社-朝霧碼頭',
        '朝霧碼頭-九龍口',
        '九龍口-文武廟',
        '文武廟-孔雀園',
        '孔雀園-大竹湖',
        '大竹湖-水蛙頭',
        '水蛙頭-纜車站'
      ]
      const valueData = [52, 32, 12, 53, 65, 36, 69, 29]
      const myChartTR = this.$echarts.init(this.$refs.chartTR)
      myChartTR.setOption({
        title: {
          text: '連外道路績效排名'
        },
        grid: {
          left: '5',
          right: '20',
          top: '40',
          bottom: '30'
        },
        xAxis: {
          show: false
        },
        tooltip: {
          show: true
        },
        yAxis: [
          {
            splitLine: 'none',
            axisLine: 'none',
            axisLabel: {
              verticalAlign: 'bottom',
              align: 'left',
              padding: [0, 0, 10, 10], // 文字与柱状图的距离
              textStyle: {
                color: '#000',
                fontSize: '14'
              }
            },
            data: labelData
          },
          {
            axisLine: 'none',
            data: valueData,
            axisLabel: {
              show: true,
              verticalAlign: 'bottom',
              align: 'right',
              padding: [0, -10, -10, 0], // 数据与图形的距离
              textStyle: {
                color: '#000',
                fontSize: '14'
              }
            }
          }
        ],
        series: [
          {
            type: 'bar',
            showBackground: true,
            data: valueData,
            barWidth: 15,
            itemStyle: {}
          }
        ],
        visualMap: {
          orient: 'horizontal',
          left: 'center',
          min: 10,
          max: 100,
          text: ['High', 'Low'],
          textStyle: {
            color: 'black',
            fontSize: 10
          },
          // Map the score column to color
          dimension: 0,
          inRange: {
            color: ['#ffcf46', '#ff0000']
          }
        }
      })
      window.addEventListener('resize', () => {
        // 自动渲染echarts
        myChartTR.resize()
      })
    },
    chartBlInt () {
      const myChartBL = this.$echarts.init(this.$refs.chartBL)
      myChartBL.setOption({
        title: {
          text: '環湖公路路段分時行駛速率趨勢'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['台21線', '台16線', '台17線', '台14線', '台12線'],
          top: 28,
          right: 0
        },
        grid: {
          left: '5',
          top: '75',
          right: '10',
          bottom: '5',
          containLabel: true
        },
        toolbox: {
          show: false,
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '台21線',
            type: 'line',
            stack: '总量',
            data: [20, 60, 75, 55, 90, 110, 75]
          },
          {
            name: '台16線',
            type: 'line',
            stack: '总量',
            data: [55, 65, 25, 75, 54, 77, 100]
          },
          {
            name: '台17線',
            type: 'line',
            stack: '总量',
            data: [35, 55, 85, 65, 12, 78, 58]
          },
          {
            name: '台14線',
            type: 'line',
            stack: '总量',
            data: [77, 88, 11, 55, 75, 45, 95]
          },
          {
            name: '台12線',
            type: 'line',
            stack: '总量',
            data: [85, 55, 45, 87, 25, 58, 45]
          }
        ]
      })
      window.addEventListener('resize', () => {
        // 自动渲染echarts
        myChartBL.resize()
      })
    },
    chartClInt () {
      const myChartCL = this.$echarts.init(this.$refs.chartCL)
      myChartCL.setOption({
        title: {
          text: '環湖公路路段分時行駛速率趨勢'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['台21線', '台16線', '台17線', '台14線', '台12線'],
          top: 28,
          right: 0
        },
        grid: {
          left: '5',
          top: '75',
          right: '10',
          bottom: '5',
          containLabel: true
        },
        toolbox: {
          show: false,
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '台21線',
            type: 'line',
            stack: '总量',
            data: [20, 60, 75, 55, 90, 110, 75]
          },
          {
            name: '台16線',
            type: 'line',
            stack: '总量',
            data: [55, 65, 25, 75, 54, 77, 100]
          },
          {
            name: '台17線',
            type: 'line',
            stack: '总量',
            data: [35, 55, 85, 65, 12, 78, 58]
          },
          {
            name: '台14線',
            type: 'line',
            stack: '总量',
            data: [77, 88, 11, 55, 75, 45, 95]
          },
          {
            name: '台12線',
            type: 'line',
            stack: '总量',
            data: [85, 55, 45, 87, 25, 58, 45]
          }
        ]
      })
      window.addEventListener('resize', () => {
        // 自动渲染echarts
        myChartCL.resize()
      })
    },
    chartBrInt () {
      const myChartBR = this.$echarts.init(this.$refs.chartBR)
      myChartBR.setOption({
        title: {
          text: '連外道路路段分時行駛速率趨勢'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['台21線', '台16線', '台17線', '台14線', '台12線'],
          top: 28,
          right: 0
        },
        grid: {
          left: '5',
          top: '75',
          right: '10',
          bottom: '5',
          containLabel: true
        },
        toolbox: {
          show: false,
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '台21線',
            type: 'line',
            stack: '总量',
            data: [35, 15, 85, 65, 12, 78, 58]
          },
          {
            name: '台16線',
            type: 'line',
            stack: '总量',
            data: [30, 25, 45, 87, 25, 58, 45]
          },
          {
            name: '台17線',
            type: 'line',
            stack: '总量',
            data: [20, 10, 75, 55, 90, 110, 75]
          },
          {
            name: '台14線',
            type: 'line',
            stack: '总量',
            data: [27, 18, 11, 55, 75, 45, 95]
          },
          {
            name: '台12線',
            type: 'line',
            stack: '总量',
            data: [25, 15, 25, 75, 54, 77, 100]
          }
        ]
      })
      window.addEventListener('resize', () => {
        // 自动渲染echarts
        myChartBR.resize()
      })
    }
  }
}
</script>

<style scoped lang="scss">
.map {
  width: 100%;
  height: 780px;
}
@media (max-width: 414px) {
  .map {
    height: 350px !important;
  }
}
.chart-pop-TL {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.65) 100%
  );
  position: absolute !important;
  padding: 10px;
  top: 70px;
  left: 20px;
  border-radius: 10px;
  width: 20%;
  height: 55%;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
    0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 /12%);
}
@media (max-width: 414px) {
  .chart-pop-TL {
    position: relative !important;
    margin-top: 20px;
    margin-bottom: 20px;
    top: 0;
    left: 0;
    width: 100%;
    height: 400px;
  }
}
.chart-pop-TR {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.65) 100%
  );
  position: absolute !important;
  padding: 10px;
  top: 70px;
  right: 20px;
  border-radius: 10px;
  width: 20%;
  height: 55%;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
    0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 /12%);
}
@media (max-width: 414px) {
  .chart-pop-TR {
    position: relative !important;
    margin-top: 20px;
    margin-bottom: 20px;
    top: 0;
    left: 0;
    width: 100%;
    height: 400px;
  }
}
.chart-pop-BL {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.65) 100%
  );
  position: absolute !important;
  padding: 10px;
  bottom: 20px;
  left: 20px;
  border-radius: 10px;
  width: 29%;
  height: 30%;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
    0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 /12%);
}
@media (max-width: 414px) {
  .chart-pop-BL {
    position: relative !important;
    margin-top: 20px;
    margin-bottom: 20px;
    top: 0;
    left: 0;
    width: 100%;
    height: 400px;
  }
}
.chart-pop-CL {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.65) 100%
  );
  position: absolute !important;
  padding: 10px;
  bottom: 20px;
  left: 32%;
  border-radius: 10px;
  width: 17.5%;
  height: 30%;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
    0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 /12%);
}
@media (max-width: 414px) {
  .chart-pop-CL {
    position: relative !important;
    margin-top: 20px;
    margin-bottom: 20px;
    top: 0;
    left: 0;
    width: 100%;
    height: 200px;
  }
}
.chart-pop-CR {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.65) 100%
  );
  position: absolute !important;
  padding: 10px;
  bottom: 20px;
  right: 32%;
  border-radius: 10px;
  width: 17.5%;
  height: 30%;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
    0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 /12%);
}
@media (max-width: 414px) {
  .chart-pop-CR {
    position: relative !important;
    margin-top: 20px;
    margin-bottom: 20px;
    top: 0;
    left: 0;
    width: 100%;
    height: 200px;
  }
}
.chart-pop-BR {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.65) 100%
  );
  position: absolute !important;
  padding: 10px;
  bottom: 20px;
  right: 20px;
  border-radius: 10px;
  width: 29%;
  height: 30%;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
    0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 /12%);
}
@media (max-width: 414px) {
  .chart-pop-BR {
    position: relative !important;
    margin-top: 20px;
    margin-bottom: 20px;
    top: 0;
    left: 0;
    width: 100%;
    height: 400px;
  }
}
.chart-title {
  font-family: 'Roboto', sans-serif !important;
  font-size: 18px;
  font-weight: bold;
}
.chart-subtitle {
  font-family: 'Roboto', sans-serif !important;
  font-size: 14px;
  font-weight: 300;
}
.chart-key-value-1 {
  text-align: center;
  padding-left: 40px;
  font-family: 'Roboto', sans-serif !important;
  font-size: 100px;
  font-weight: bold;
  color: #f51270;
}
.chart-key-value-2 {
  text-align: center;
  padding-left: 40px;
  font-family: 'Roboto', sans-serif !important;
  font-size: 100px;
  font-weight: bold;
  color: #f59a12;
}
.chart-key-value-1 span {
  font-size: 16px;
  position: relative;
  left: -20px;
}

.chart-key-value-2 span {
  font-size: 16px;
  position: relative;
  left: -20px;
}
</style>
